import $ from 'jquery'

export default class JapanMap {
  constructor() {
    this.imgPath = '/assets/image/common/map/'
    this.canvas = document.getElementById('canvas')
    this.ctx = this.canvas ? this.canvas.getContext('2d') : null
  }

  init() {
    if (this.canvas) {
      this.drawMap()
      this.handleHover()
    }
  }

  drawMap() {
    this.draw(`${this.imgPath}img_map_japan.png`)
  }

  draw(url) {
    const img = new Image()
    img.onload = () => {
      this.ctx.drawImage(img, 0, 0)
    }
    img.src = url
  }

  handleHover() {
    const $item = $('.c-gnavi-list-map__list-prefectures')
    $item.children().each((idx, val) => {
      $(val).on('mouseenter', e => {
        const $prefecture = $(e.currentTarget)
        const name = $prefecture.children().data('name')
        this.clearCanvas()
        this.draw(`${this.imgPath}img_map_japan_${name}.png`)
      })
      $(val).on('mouseleave', () => {
        this.clearCanvas()
        this.drawMap()
      })
    })
  }

  clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }
}
