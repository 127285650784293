import $ from 'jquery'

export default class BgGenerate {
  constructor() {
    this.ele = document.querySelector('html')
  }

  init() {
    this.bgGenerate()
  }

  bgGenerate() {
    $('[data-bg-image]').each((idx, ele) => {
      const bgPath = $(ele).data('bg-image')
      $(ele).css('background-image', `url(${bgPath})`)
    })
  }
}
