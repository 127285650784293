import $ from 'jquery'
import 'jquery-match-height'
import Screen from './screen'

const screen = new Screen()

export default class MatchHeight {
  constructor() {
    this.ele = document.querySelector('html')
  }

  init() {
    this.setMatchHeight()
  }

  setMatchHeight() {
    $('.js-matchHeight-group').each((idx, ele) => {
      const $this = $(ele)

      $this.find('.js-matchHeight-target').matchHeight()

      if ($this.find('.js-matchHeight-target-two').length) {
        $this.find('.js-matchHeight-target-two').matchHeight()
      }

      if ($this.find('.js-matchHeight-target-three').length) {
        $this.find('.js-matchHeight-target-three').matchHeight({
          byRow: false,
        })
      }

      if ($this.find('.js-matchHeight-target-md').length) {
        if (screen.isPC) {
          $this.find('.js-matchHeight-target-md').matchHeight({
            byRow: false,
          })
        }
      }
    })
  }
}
