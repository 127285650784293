import $ from 'jquery'
import Pagetop from './modules/pagetop'
import Header from './modules/header'
import Gnavi from './modules/gnavi'
import Top from './modules/top'
import AnchorLink from './modules/anchorLink'
import MatchHeight from './modules/match_height'
import BgGenerate from './modules/bg_generate'
import Screen from './modules/screen'
import Prefecture from './modules/prefecture'
import FixedAssist from './modules/fixed_assits'

/* ----------------------------------------------------------
  init
---------------------------------------------------------- */
const pagetop = new Pagetop({
  target: '.js-pagetop'
})

const anchorLink = new AnchorLink({
  target: '.js-anchor'
})

const matchHeight = new MatchHeight()

const header = new Header()

const gnavi = new Gnavi()

const top = new Top()

const bgGenerate = new BgGenerate()

const prefecture = new Prefecture()

const fixedAssist = new FixedAssist({
  target: '.js-fixed-assist'
})

header.init()
gnavi.init()
top.init()
pagetop.init()
anchorLink.init()
matchHeight.init()
bgGenerate.init()
prefecture.init()
fixedAssist.init()

/* ----------------------------------------------------------
  Screen
---------------------------------------------------------- */
const screen = new Screen()
$(window).on('resize', screen.size())
$(window).on('breakpointChange')
