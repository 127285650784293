import $ from 'jquery'
import { isOpen } from '../_variables'
import Screen from './screen'

const screen = new Screen()

export default class Header {
  constructor() {
    this.ele = document.querySelector('html')
  }

  init() {
    this.showSelectBox()
    this.resize()
  }

  // Click button to show select
  showSelectBox() {
    $('.js-show-select').on('click', e => {
      const $this = $(e.currentTarget)
      const $target = $(e.target)
      const $selectBox = $this.children('.c-header-links__select')
      if (screen.isPC) {
        if ($target.closest('.c-form-select-search').length === 0) {
          e.preventDefault()
          $this.toggleClass(isOpen)
          $selectBox.stop().animate({
            width: 'toggle',
            opacity: 'toggle'
          }, 200)
        }
      } else {
        const targetLink = $(e.currentTarget).attr('href')
        window.location.href = targetLink
      }
    })
    if (!screen.isPC) {
      $('.c-header-links__select').removeAttr('style')
      $('.js-show-select').removeClass(isOpen)
    }
  }

  // resize
  resize() {
    $(window).on('breakpointChange', () => {
      this.showSelectBox()
    })
  }
}
