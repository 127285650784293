import $ from 'jquery'
import { isShow } from '../_variables'

export default class FixedAssits {
  constructor(props) {
    this.elm = document.querySelector(props.target)
  }

  init() {
    if (this.elm) {
      const $content = $('.js-fixed-assist-application')
      const $search = $('.js-fixed-assist-search')

      $(this.elm).children().each((idx, val) => {
        $(val).on('click', e => {
          if (idx !== 1) {
            e.preventDefault()
            if (idx === 0) {
              $content.stop().toggleClass(isShow)
              $search.removeClass(isShow)
            } else if (idx === 2) {
              $search.stop().toggleClass(isShow)
              $content.removeClass(isShow)
            }
          }
        })
      })
    }
  }
}
