import $ from 'jquery'
import 'slick-carousel'

export default class Prefecture {
  constructor() {
    this.ele = document.querySelector('html')
  }

  init() {
    this.bnrSlidePrefecture()
  }

  // banner slider
  bnrSlidePrefecture() {
    $('.p-slider-prefecture__slider').slick({
      dots: true,
      arrows: true,
      cssEase: 'ease-in-out',
      infinite: true,
      autoplay: true,
      speed: 1200,
      slidesToShow: 3,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      rows: 0,
      prevArrow: $('.p-slider-prefecture__prev'),
      nextArrow: $('.p-slider-prefecture__next'),
      responsive: [{
        breakpoint: 897,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }]
    })
  }
}
