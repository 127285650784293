import $ from 'jquery'
import 'slick-carousel'
import Screen from './screen'

const screen = new Screen()

export default class Top {
  constructor() {
    this.ele = document.querySelector('html')
  }

  init() {
    this.bnrSlideTop()
    this.setSlider()
    this.resize()
  }

  // banner slider
  bnrSlideTop() {
    $('.p-bnr-slider-top__slider').slick({
      dots: false,
      arrows: true,
      cssEase: 'ease-in-out',
      infinite: true,
      autoplay: true,
      speed: 1500,
      autoplaySpeed: 15000,
      slidesToShow: 4,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      rows: 0,
      prevArrow: $('.p-bnr-slider-top__prev'),
      nextArrow: $('.p-bnr-slider-top__next'),
      responsive: [{
        breakpoint: 897,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    })
  }

  // main visual banner slider
  mvBnrSlide() {
    $('.p-mv-bnr-slider').slick({
      dots: true,
      arrows: false,
      cssEase: 'ease-in-out',
      fade: true,
      speed: 1500,
      autoplaySpeed: 10000,
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      rows: 0
    })
  }

  // news feed slider
  newsFeedSlide() {
    $('.p-news-feed__slider').slick({
      dots: false,
      arrows: true,
      cssEase: 'ease-in-out',
      fade: true,
      speed: 1500,
      autoplaySpeed: 10000,
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      pauseOnFocus: false,
      rows: 0,
      prevArrow: $('.p-news-feed__prev'),
      nextArrow: $('.p-news-feed__next'),
    })
  }

  // set slider
  setSlider() {
    if (screen.isPC) {
      // set banner slider
      if (!$('.p-mv-bnr-slider').hasClass('slick-initialized')) {
        this.mvBnrSlide()
      }
      // set news feed slider
      if (!$('.p-news-feed__slider').hasClass('slick-initialized')) {
        this.newsFeedSlide()
      }
    } else if (!screen.isPC) {
      // remove banner slider
      if ($('.p-mv-bnr-slider').hasClass('slick-initialized')) {
        $('.p-mv-bnr-slider').removeClass('slick-initialized').slick('unslick')
      }
      // remove news feed slider
      if ($('.p-news-feed__slider').hasClass('slick-initialized')) {
        $('.p-news-feed__slider').removeClass('slick-initialized').slick('unslick')
      }
    }
  }

  // resize
  resize() {
    $(window).on('breakpointChange', () => {
      this.setSlider()
    })
  }
}
