import $ from 'jquery'
import { isShow, isHover, isOpen } from '../_variables'
import Screen from './screen'
import JapanMap from './japanmap'

const screen = new Screen()
const japanMap = new JapanMap()

export default class Gnavi {
  constructor() {
    this.ele = document.querySelector('html')
  }

  init() {
    this.gnavi()
    this.closeMegaMenu()
    this.spMenu()
    this.linkMap()
    this.closeSPMenu()
    this.othersMenu()
    this.hoverPrefectures()
  }

  // Click gnavi
  gnavi() {
    $('.c-list-gnavi__item').each((idx, elGnavi) => {
      const $this = $(elGnavi)
      const $btnGnavi = $this.find('.c-list-gnavi__text')
      const $btnLink = $this.find('.c-list-gnavi__text--notice')
      const $megaMenu = $this.find('.c-gnavi-content')
      const $btnMapClose = $this.find('.c-gnavi-map__btn-close')
      const speed = 200

      // Click button to show mega menu
      $btnGnavi.not('.c-list-gnavi__text--notice').on('click', () => {
        if (screen.isPC) {
          this.resetMegaMenu()

          if ($megaMenu.is(':hidden')) {
            $megaMenu.stop().fadeIn(speed)
            $this.addClass(isShow)

            // load map
            if ($megaMenu.hasClass('c-gnavi-content--map')) {
              japanMap.init()
            }

            // update matchHeight
            $.fn.matchHeight._update()

            // Delete select search
            this.delSelectSearch()
          }
        } else {
          $megaMenu.stop().slideToggle()
          $this.toggleClass(isShow)
        }
      })

      // Click link
      $btnLink.on('click', e => {
        if (screen.isPC) {
          e.preventDefault()
          this.resetMegaMenu()

          if ($megaMenu.is(':hidden')) {
            $megaMenu.stop().fadeIn(speed)
            $this.addClass(isShow)

            // Delete select search
            this.delSelectSearch()
          }
        } else {
          $('.c-gnavi-content--notice').removeAttr('style')
          $('.c-list-gnavi__item').removeClass(isShow)
        }
      })

      // Click button map to close
      $btnMapClose.on('click', () => {
        $megaMenu.stop().fadeOut(speed)
        $this.removeClass(isShow)

        // remove Map
        japanMap.clearCanvas()
      })

      // resize
      $(window).on('breakpointChange', () => {
        $('.c-gnavi-content').stop().fadeOut(speed).removeAttr('style')
        $('.c-list-gnavi__item').removeClass(isShow)
      })
    })
  }

  // Reset mega menu
  resetMegaMenu() {
    const speed = 200
    $('.c-gnavi-content').stop().fadeOut(speed)
    $('.c-list-gnavi__item').removeClass(isShow)
    $('.c-header-links__link').removeClass(isShow)
  }

  // Click button to close mega menu
  closeMegaMenu() {
    $('.c-gnavi-content__btn-close').on('click', () => {
      const speed = 200
      $('.c-gnavi-content').stop().fadeOut(speed)
      $('.c-list-gnavi__item').removeClass(isShow)
      $('.c-header-links__link').removeClass(isShow)

      // Delete select search
      if ($('.c-gnavi-content--application').is(':visible')) {
        $('.c-header-links__select').stop().animate({
          width: 'toggle'
        }, 200)
      }
    })
  }

  // SP menu
  spMenu() {
    $('.c-header-menu').on('click', () => {
      if (!screen.isPC) {
        $('.c-gnavi').addClass(isShow)

        if ($('.c-gnavi').hasClass(isShow)) {
          screen.lock()
        } else {
          screen.unlock()
        }
      }

      // resize
      $(window).on('breakpointChange', () => {
        if (screen.isPC) {
          $('.c-gnavi').removeClass(isShow)
          screen.unlock()
        }
      })
    })
  }

  // SP click link to open hamburger menu
  linkMap() {
    $('.js-link-map').on('click', () => {
      if (!screen.isPC) {
        const $list = $('.js-content-map')
        const $content = $list.find('.c-gnavi-content')

        // open accordion
        $('.c-gnavi').addClass(isShow)
        $content.stop().slideToggle()
        $list.toggleClass(isShow)

        if ($('.c-gnavi').hasClass(isShow)) {
          screen.lock()
        } else {
          screen.unlock()
        }
      }

      // resize
      $(window).on('breakpointChange', () => {
        if (screen.isPC) {
          $('.c-gnavi').removeClass(isShow)
          screen.unlock()
        }
      })
    })
  }

  // Click to close SP menu
  closeSPMenu() {
    $('.c-gnavi-close__button').on('click', () => {
      $('.c-gnavi').removeClass(isShow)
      $('.c-list-gnavi__item').removeClass(isShow)
      $('.c-gnavi-content').removeAttr('style')
      screen.unlock()
    })
  }

  // PC click others button
  othersMenu() {
    $('.c-header-links__list').each((idx, elBtn) => {
      const $this = $(elBtn)
      const $applicationBtn = $this.find('.js-menu-application')
      const $faqBtn = $this.find('.js-menu-faq')
      const $associationBtn = $this.find('.js-menu-association')
      const speed = 200

      // Click application button
      $applicationBtn.on('click', e => {
        const $self = $(e.currentTarget)
        const $target = $(e.target)
        const $selectBox = $self.children('.c-header-links__select')

        if (screen.isPC) {
          e.preventDefault()

          if ($target.closest('.c-form-select-search').length === 0) {
            $self.toggleClass(isOpen)
            $selectBox.stop().animate({
              width: 'toggle'
            }, 200)

            this.resetMegaMenu()

            if ($('.c-gnavi-content--application').is(':hidden')) {
              $('.c-gnavi-content--application').stop().fadeIn(speed)
              $applicationBtn.addClass(isShow)
            }
          }
        }
      })

      // Click faq button
      $faqBtn.on('click', e => {
        if (screen.isPC) {
          e.preventDefault()
          this.resetMegaMenu()

          if ($('.c-gnavi-content--faq').is(':hidden')) {
            $('.c-gnavi-content--faq').stop().fadeIn(speed)
            $faqBtn.addClass(isShow)

            // Delete select search
            this.delSelectSearch()
          }
        }
      })

      // Click association button
      $associationBtn.on('click', e => {
        if (screen.isPC) {
          e.preventDefault()
          this.resetMegaMenu()

          if ($('.c-gnavi-content--association').is(':hidden')) {
            $('.c-gnavi-content--association').stop().fadeIn(speed)
            $associationBtn.addClass(isShow)

            // Delete select search
            this.delSelectSearch()
          }
        }
      })

      // resize
      $(window).on('breakpointChange', () => {
        if (!screen.isPC) {
          $('.c-header-links__link').removeClass(isShow)
        }
      })
    })
  }

  // Delete select search
  delSelectSearch() {
    if ($('.c-header-links__select').is(':visible')) {
      $('.c-header-links__select').stop().animate({
        width: 'toggle'
      }, 200)
    }
  }

  // Hover prefectures
  hoverPrefectures() {
    $('.c-gnavi-list-map__link').each((index, ele) => {
      const $this = $(ele)
      const $list = $this.parents('.c-gnavi-list-map__list')

      $this.hover(() => {
        if (screen.isPC) {
          $list.addClass(isHover)
        } else {
          $list.removeClass(isHover)
        }
      }, () => {
        $list.removeClass(isHover)
      })

      // resize
      $(window).on('breakpointChange', () => {
        if (!screen.isPC) {
          $list.removeClass(isHover)
        }
      })
    })
  }
}
